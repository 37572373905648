import React from "react";
import * as S from "./mainSearch.style";
import { InputText } from "../../atoms/Input/InputText";
import { InputDate } from "../../atoms/Input/InputDate";
import { InputSelect } from "../../atoms/Input/InputSelect";

const MainSearch = ({ setSearch, search }: any) => {
  return (
    <>
      <S.SearchWrapper>
        <InputSelect
          label="년도"
          options={[
            {
              value: "2023",
            },
            {
              value: "2022",
            },
            {
              value: "2021",
            },
          ]}
          layout="row"
          themeType="admin"
          size="lg"
          onChange={({ target }: any) =>
            setSearch({ ...search, birth: target.value })
          }
          value={search.birth}
        />
        <InputText
          label="이름"
          layout="row"
          type="text"
          themeType="admin"
          size="lg"
          width="100%"
          placeholder="입력..."
          onChange={({ target }: any) =>
            setSearch({ ...search, name: target.value })
          }
        />
        <InputText
          label="아이디"
          layout="row"
          type="text"
          themeType="admin"
          size="lg"
          width="100%"
          placeholder="입력..."
          onChange={({ target }: any) =>
            setSearch({ ...search, id: target.value })
          }
        />
      </S.SearchWrapper>
    </>
  );
};

export default MainSearch;
