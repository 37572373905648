import React, { useState } from "react";
import { MainPage } from "../../components/templates/MainPage";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

export interface SearchState {
  name: string;
  id: string;
  birth: number;
}

const Main = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  const [search, setSearch] = useState<SearchState>({
    name: "",
    id: "",
    birth: 2023,
  });

  const signOutHandler = () => {
    signOut();
    navigate("/");
  };

  return (
    <MainPage
      search={search}
      setSearch={setSearch}
      signOutHandler={signOutHandler}
    />
  );
};

export default Main;
